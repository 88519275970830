import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";
import packageJson from '../package.json';
import { serverErrorsRegex } from './app/utils/utils';

Sentry.init({
  dsn: environment.sentryDsn,
  release: packageJson.version,  
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 20% of the transactions
   ignoreErrors: [serverErrorsRegex],
  } ,  
  );

if (environment.production) {
  // enableProdMode();
  if (window) {
    window.console.log = function () {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
