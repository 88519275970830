import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error500Component } from './pages/error500/error500.component';
import { Error404Component } from './pages/error404/error404.component';
import { BookingFailedComponent } from './pages/booking-failed/booking-failed.component';
import { appConstants } from './app.constants';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      isCurrentDomainTreasures
        ? import('./pages/treasures-homepage/treasures-homepage.module').then(
            (m) => m.TreasuresHomepageModule
          )
        : import('./pages/homepage/homepage.module').then(
            (m) => m.HomepageModule
          ),
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./pages/offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: 'offers/:category',
    loadChildren: () =>
      import('./pages/homepage/homepage.module').then((m) => m.HomepageModule),
  },
  {
    path: 'offers/:category/:sub_category',
    loadChildren: () =>
      import('./pages/homepage/homepage.module').then((m) => m.HomepageModule),
  },

  {
    path: 'memberships',
    loadChildren: () =>
      import('./pages/memberships/memberships.module').then(
        (m) => m.MembershipsModule
      ),
  },
  {
    path: 'giftcards',
    loadChildren: () =>
      import('./pages/gift-cards/gift-cards.module').then(
        (m) => m.GiftCardsModule
      ),
  },
  {
    path: 'offers-on-giftcards',
    loadChildren: () =>
      import('./pages/gift-cards/gift-cards.module').then(
        (m) => m.GiftCardsModule
      ),
  },
  {
    path: 'hotels',
    loadChildren: () =>
      import('./pages/hotels/hotels.module').then((m) => m.HotelsModule),
  },

  {
    path: 'charitable-donations',
    loadChildren: () =>
      import('./pages/charitable-giving/charitable-giving.module').then(
        (m) => m.CharitableGivingModule
      ),
  },
  {
    path: 'redemption-history',
    loadChildren: () =>
      import('./pages/redemption/redemption.module').then(
        (m) => m.RedemptionModule
      ),
  },
  { path: 'error', component: Error500Component },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'confirm-email/:code',
    loadChildren: () =>
      import('./pages/email-confirmation/email-confirmation.module').then(
        (m) => m.EmailConfirmationModule
      ),
  },
  {
    path: 'sso_login',
    loadChildren: () =>
      import('./pages/loginSso/loginSso.module').then((m) => m.LoginSsoModule),
  },
  {
    path: 'flights',
    loadChildren: () =>
      import('./pages/flights/flights.module').then((m) => m.FlightsModule),
  },
  {
    path: 'bus',
    loadChildren: () =>
      import('./pages/bus/bus.module').then((m) => m.BusModule),
  },
  {
    path: 'golf',
    loadChildren: () =>
      import('./pages/golf/golf.module').then((m) => m.GolfModule),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./pages/booking-history/booking-history.module').then(
        (m) => m.BookingHistoryModule
      ),
  },
  { path: 'booking-failed', component: BookingFailedComponent },
  {
    path: 'exclusive-rewards/:campaign_id',
    loadChildren: () =>
      import('./pages/campaigns/campaigns.module').then(
        (m) => m.CampaignsModule
      ),
  },
  {
    path: 'exclusive-rewards',
    loadChildren: () =>
      import('./pages/homepage/homepage.module').then((m) => m.HomepageModule),
  },
  // {
  //   path:"electronics",
  //   loadChildren: () =>
  //     import("./pages/electronics/electronics.module").then(
  //       m => m.ElectronicsModule
  //     )
  // },
  {
    path: 'points-xchange',
    loadChildren: () =>
      import('@pv-frontend/points-xchange').then(
        (m) => m.PvPointsXchangeModule
      ),
  },
  {
    path: 'treasures',
    loadChildren: () =>
      import('./pages/treasures/treasures.module').then(
        (m) => m.TreasuresModule
      ),
  },
  { path: '**', pathMatch: 'full', component: Error404Component },
];

const isCurrentDomainTreasures: boolean = window.location.hostname.includes(
  appConstants.dbsTreasuresUrlKeyword
);

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
