import { Observable, ReplaySubject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

export type CardType = 'temp' | 'vantage' | 'bfl' | 'mass';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private active: Theme = light;
  private availableThemes: Theme[] = [light, dark, mass, treasures];
  private themeChangeEvent: ReplaySubject<any> = new ReplaySubject<any>();
  cardType: CardType = 'temp';
  getAvailableThemes(): any[] {
    return ['light', 'dark', 'mass', 'treasures'];
  }

  get getActiveTheme(): Theme {
    return this.active;
  }

  get getActiveCard(): CardType {
    return this.cardType;
  }

  get getActiveThemeName(): string {
    return this.active.name;
  }

  get isDarkTheme(): boolean {
    return this.active.name === dark.name;
  }

  get isTreasuresTheme(): boolean {
    return this.active.name === treasures.name;
  }

  setThemes(
    theme: 'light' | 'dark' | 'mass' | 'treasures',
    defaultAction = 'none'
  ) {
    const themeMapper = {
      light: light,
      dark: dark,
      mass: mass,
      treasures: treasures,
    };
    const cardMapper: { [x: string]: CardType } = {
      light: 'bfl',
      dark: 'vantage',
      mass: 'mass',
    };

    this.setActiveTheme(themeMapper[theme]);
    this.setActiveCard(cardMapper[theme]);
    this.themeChangeEvent.next(defaultAction);
  }
  themeChanges(): Observable<any> {
    return this.themeChangeEvent.asObservable();
  }
  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        `${property}`,
        `${this.active.properties[property]}`
      );
    });
  }

  setActiveCard(card: CardType) {
    localStorage.setItem('cardType', this.cardType);
    this.cardType = card;
  }
}

export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--primary-brown-radial':
      'radial-gradient(100% 100% at 0% 0%, #AD8B5B 0%, rgba(172, 138, 88, 0.5) 100%)',

    '--primary-bg-black': '#000000',
    '--primary-font-black': 'rgba(35, 31, 32, 0.9)',
    '--primary-font-light': 'white',
    '--brand-color': '#FF3E3E',
    '--brand-color-filter':
      'invert(0%) sepia(0%) saturate(14%) hue-rotate(229deg) brightness(97%) contrast(103%) invert(32%) sepia(30%) saturate(3165%) hue-rotate(333deg) brightness(115%) contrast(100%)',
    '--primary': '#FF3E3E ',
    '--primary-translucent': 'hsla(0, 100%, 62%, 50%)',
    '--icon-primary-black': 'rgba(35, 31, 32, 0.9)',
    '--primary-grey-accent': '#828282',

    // '--primary-grey-accent':'#828282',

    '--header-background-color': 'white',
    '--header-header-links-bg-color': 'var(--primary-bg-black)',
    '--header-header-links-hover-bg-color': 'var(--primary-brown)',
    '--header-profile-dropdown-bg-color': 'white',
    '--header-profile-dropdown-option-hover-color': 'var(--primary-bg-black)', //D8D8D8
    '--header-profile-dropdown-font-color': 'var(--primary-font-black)', //333
    '--header-profile-dropdown-font-hover-color': 'white', //333
    '--header-profile-border-left': '#f0f0f0',
    '--login-button-color': 'var(--primary)',

    '--main-container-bg-color': 'white', //#fff
    '--main-container-font-color': 'var(--primary-font-black)',

    '--d-c-info-background': 'transparent',
    '--d-c-info-border-color': '#DDE4E9', // #C7CFD5
    '--d-c-icon-color': 'var(--brand-color)',

    '--reward-bar-background-color': '#FFEBBC', //##FFEBBC
    '--home-bottom-background-color': 'white', // white,
    '--home-bottom-category-active-border-color': 'var(--brand-color)', // --brand-color
    '--giftcard-bg-color': 'white', // white
    '--giftcard-heading': '#222222', //#222222
    '--giftcard-primary-btn': '#ff3e3e', //#ff3e3e

    '--membership-card-bg-color': 'white', // white
    '--membership-card-border-color': 'transparent', //transparent

    '--denomination-heading-bg-color': 'black',
    '--denomination-heading-color': '#fff',
    '--denomination-body-border-color': '#f5f5f5', // #f5f5f5,
    '--denomination-nth-bg-color': '#fafafa', //#fafafa

    '--range-slider-knob': 'var(--brand-color)', // brand-color
    '--range-slider-line': '#e0e0e0', // #e0e0e0
    '--range-slider-ticks': '#d3d3d3', // #d3d3d3
    '--range-slider-input': '#a1a8b0', // #a1a8b0
    '--range-slider-input-text': 'black', // black

    '--send-enquiry-desktop-font-color': '#333', // #333
    '--send-enquiry-desktop-background': 'white', // white

    '--list-body-mini-cards-list-bg': '#f8f9f9', // #f8f9f9

    '--filter-head-item-active': '#FDBF00', //#FDBF00

    '--philanthropy-cause-card-border': 'var(--brand-color)',
    '--philanthropy-cause-img-bg': 'var(--brand-color)',
    '--philanthropy-cause-img-hover-bg':
      'linear-gradient(135deg, rgba(172, 138, 88, 0.25) 0%, rgba(173, 139, 91, 0.1875) 100%)',
    '--philanthropy-denom-bg-color': 'white',

    '--cause-list-page-bg-color': '#FAFAFA',
    '--cause-list-box-shadow-color': '0px 2px 10px rgba(0, 0, 0, 0.15)',
    '--cause-list-bg-color': 'white',

    '--offers-banner-bg': 'white',
    '--offers-coupon-modal-bg': '#f6f6f9',
    '--offer-coupon-modal-circle-left-bg': '#f6f6f9',
    '--offer-coupon-modal-circle-right-bg': '#f6f6f9',
    '--points-payment-cell-badge-color': '#FFD7D7',

    '--option-hover': 'rgba(253, 191, 0, 0.22)',

    '--input-box-color': '#F8F9F9',
    '--dark-gray': '#54565B',

    '--tertiary': '#485473 ',
    '--accent': '#54565B ',
    '--card-accent': '#54565B ',
    '--accenttwo': '#54565B ',
    '--darkblack': '#000 ',
    '--black': '#2E2E2E ',
    '--blacktwo': '#2E2E2E ',
    '--secblack': '#555151 ',
    '--black-1': '#64707C ',
    '--secblacktwo': 'rgba(85, 81, 81, 0.5) ',
    '--secblackthree': '#52555A ',
    '--grey': '#445261 ',
    '--greyone': '#64707C ',
    '--greytwo': 'rgba(128, 145, 163, 0.5) ',
    '--greythree': 'rgba(128, 145, 163, 0.3) ',
    '--greyfour': '#64707C',
    '--greyfive': '#8f97a0 ',
    '--secondarygrey': '#9A9393',
    '--background': '#F8F9F9 ',
    '--card-background': 'var(--background)',
    '--secondarybackground': '#FAFAFA ',
    // '--green': '#27AE60 ',
    '--greenone': '#219653 ',
    '--greentwo': '#055F2B ',
    '--greenBackground': '#EDFFF5 ',
    '--red': '#EB5757 ',
    '--redone': '#690101 ',
    '--redBackground': '#FFEDED ',
    '--option': '#F8F9F9 ',
    '--optionhover': '#F6F6F6 ',
    '--white': '#fff ',
    '--disabledbutton': '#C8D3DC ',
    '--stroke': '#ECEEEF ',
    '--border': '#DDE4E9',
    '--border-bottom': '#4990EF ',
    '--dark-blue': '#334C8B ',
    '--dark-bluetwo': '#03224C ',
    '--darkbluethree': '#213757 ',
    '--green': '#6FCF97 ',
    '--greyborder': '#E3E5E7',
    '--greytext': '#DBE0EB',
    '--hover': '#0b6aea ',
    '--graybackground': '#E5E5E5 ',
    '--errorborder': '#FF4C4C ',
    '--errorbackground': '#FFF2F2 ',
    '--info': '#445261 ',
    '--statusbackground': '#E4F2FF ',
    '--blueone': '#2484e8 ',
    '--bluetwo': '#56CCF2 ',
    '--orange': '#F7AA00 ',
    '--voilet': '#2D215D ',
    '--pink1': '#F7DDD8 ',
    '--tertiarybackground': '#F9FAFE ',
    '--yellowone': '#FFD5#4F ',
    '--primary-btn': '#FF3E3E ',
    '--text-primary': '#000',
    '--golf-dark-gradient': '#fdbf00',
    '--giftcard-mobile-bg': '#fbfbfb',
    '--charity-mob-bg': '#fafafa',
    '--main-bg': '#fafafa',
    '--golf-background': '#FFFFFF',
    '--golf-primary-1': 'var(--brand-color)',
    '--golf-primary-2': '#231F20',
    '--golf-text': '#231F20',
    '--golf-accent': '#A5A5A5',
    '--golf-accent-2': '#585858',
    '--golf-linear-gradient': 'var(--primary-btn)',
    '--golf-info-text': 'var(--golf-background)',
    '--golf-price': '#697581',
    '--card-border-radius': '12px',
    '--button-border-radius': '12px',
    '--primary-brown': 'var(--brand-color)',
    '--primary-btn-disabled': 'rgba(255, 62, 62,0.7)',

    '--footer-background': '#fff)',
    '--footer-text': 'var(--grey)',

    '--booking-confirmed-title': 'var(--black)',
    '--tabs-background': '#F8F9F9',
    '--tabs-border': '#DDE4E9',

    '--background-semantic-success': '#84bd00',
    '--background-semantic-warning': '#de6421',
    '--background-accent-light': '#ff5959',
    '--background-accent-light-opacity': '#ff595933',
    '--background-accent-medium': '#ff3e3e',
    '--background-accent-strong': '#d93434',
    '--background-neutral-10': '#eef2f5',
    '--background-neutral-100': '#172733',
    '--background-neutral-15': '#dde4e9',
    '--background-neutral-20': '#c7cfd5',
    '--background-neutral-30': '#b0b9c0',
    '--background-neutral-40': '#9ba4ab',
    '--background-neutral-60': '#6f7a81',
    '--background-neutral-70': '#59656d',
    '--background-neutral-80': '#455057',
    '--background-neutral-90': '#303c44',
    '--background-neutral-bg': '#fafdff',
    '--background-neutral-other': '#858f96',
    '--background-neutral-white': '#ffffff',
    '--background-secondary-light': '#f0b148',
    '--background-secondary-medium': '#c47f00',
    '--background-secondary-strong': '#a36a00',
    '--text-semantic-success': '#84bd00',
    '--text-semantic-warning': '#de6421',
    '--text-accent-light': '#ff5959',
    '--text-accent-light-opacity': '#ff595966',
    '--text-accent-medium': '#ff3e3e',
    '--text-accent-strong': '#d93434',
    '--text-neutral-10': '#eef2f5',
    '--text-neutral-15': '#dde4e9',
    '--text-neutral-20': '#c7cfd5',
    '--text-neutral-30': '#b0b9c0',
    '--text-neutral-40': '#9ba4ab',
    '--text-neutral-60': '#6f7a81',
    '--text-neutral-80': '#455057',
    '--text-neutral-90': '#303c44',
    '--text-neutral-bg': '#fafdff',
    '--text-neutral-body': '#59656d',
    '--text-neutral-other': '#858f96',
    '--text-neutral-title': '#172733',
    '--text-neutral-white': '#ffffff',
    '--text-secondary-light': '#f0b148',
    '--text-secondary-medium': '#c47f00',
    '--text-secondary-strong': '#a36a00',
    '--maskbg': 'rgba(0, 0, 0, 0.32)',
    '--feedback-background-positive-low-contrast': 'hsla(155, 100%, 31%, 0.18)',
    '--feedback-background-negative-low-contrast': 'hsla(9, 91%, 56%, 0.18)',
    '--feedback-background-notice-low-contrast': 'hsla(36, 100%, 44%, 0.09)',
    '--feedback-background-information-low-contrast':
      'hsla(193, 100%, 35%, 0.09)',
    '--feedback-background-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.09)',
    '--feedback-background-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-background-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-background-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-background-information-high-contrast':
      'hsla(193, 100%, 35%, 1)',
    '--feedback-background-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-border-positive-low-contrast': 'hsla(155, 100%, 31%, 0.32)',
    '--feedback-border-negative-low-contrast': 'hsla(9, 91%, 56%, 0.32)',
    '--feedback-border-notice-low-contrast': 'hsla(36, 100%, 44%, 0.32)',
    '--feedback-border-information-low-contrast': 'hsla(193, 100%, 35%, 0.32)',
    '--feedback-border-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.32)',
    '--feedback-border-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-border-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-border-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-border-information-high-contrast': 'hsla(193, 100%, 35%, 1)',
    '--feedback-border-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-text-positive': 'hsla(160, 100%, 26%, 1)',
    '--feedback-text-negative': 'hsla(8, 73%, 47%, 1)',
    '--feedback-text-notice': 'hsla(38, 97%, 38%, 1)',
    '--feedback-text-information': 'hsla(193, 100%, 35%, 1)',
    '--feedback-text-neutral': 'hsla(216, 27%, 36%, 1)',
    '--surface-border': 'var(--background-neutral-40)',
    '--surface-text-muted-low-contrast': 'var(--text-neutral-white)',
  },
};

export const mass: Theme = {
  name: 'mass',
  properties: {
    '--primary-brown-radial':
      'radial-gradient(100% 100% at 0% 0%, #AD8B5B 0%, rgba(172, 138, 88, 0.5) 100%)',

    '--primary-bg-black': '#000000',
    '--primary-font-black': 'rgba(35, 31, 32, 0.9)',
    '--primary-font-light': 'white',
    '--brand-color': '#FF3E3E',
    '--brand-color-filter':
      'invert(0%) sepia(0%) saturate(14%) hue-rotate(229deg) brightness(97%) contrast(103%) invert(32%) sepia(30%) saturate(3165%) hue-rotate(333deg) brightness(115%) contrast(100%)',
    '--primary': '#FF3E3E ',
    '--primary-translucent': 'hsla(0, 100%, 62%, 50%)',
    '--icon-primary-black': 'rgba(35, 31, 32, 0.9)',
    '--primary-grey-accent': '#828282',
    '--heading-text-color': '#CA2026',

    // '--primary-grey-accent':'#828282',

    '--header-background-color': 'white',
    '--header-header-links-bg-color': 'var(--primary-bg-black)',
    '--header-header-links-hover-bg-color': 'var(--primary-brown)',
    '--header-profile-dropdown-bg-color': 'white',
    '--header-profile-dropdown-option-hover-color': 'var(--primary-bg-black)', //D8D8D8
    '--header-profile-dropdown-font-color': 'var(--primary-font-black)', //333
    '--header-profile-dropdown-font-hover-color': 'white', //333
    '--header-profile-border-left': '#f0f0f0',
    '--login-button-color': 'var(--primary)',

    '--main-container-bg-color': 'white', //#fff
    '--main-container-font-color': 'var(--primary-font-black)',

    '--d-c-info-background': 'transparent',
    '--d-c-info-border-color': '#DDE4E9', // #C7CFD5
    '--d-c-icon-color': 'var(--brand-color)',

    '--reward-bar-background-color': '#FFEBBC', //##FFEBBC
    '--home-bottom-background-color': 'white', // white,
    '--home-bottom-category-active-border-color': 'var(--brand-color)', // --brand-color
    '--giftcard-bg-color': 'white', // white
    '--giftcard-heading': '#222222', //#222222
    '--giftcard-primary-btn': '#ff3e3e', //#ff3e3e

    '--membership-card-bg-color': 'white', // white
    '--membership-card-border-color': 'transparent', //transparent

    '--denomination-heading-bg-color': 'black',
    '--denomination-heading-color': '#fff',
    '--denomination-body-border-color': '#f5f5f5', // #f5f5f5,
    '--denomination-nth-bg-color': '#fafafa', //#fafafa

    '--range-slider-knob': 'var(--brand-color)', // brand-color
    '--range-slider-line': '#e0e0e0', // #e0e0e0
    '--range-slider-ticks': '#d3d3d3', // #d3d3d3
    '--range-slider-input': '#a1a8b0', // #a1a8b0
    '--range-slider-input-text': 'black', // black

    '--send-enquiry-desktop-font-color': '#333', // #333
    '--send-enquiry-desktop-background': 'white', // white

    '--list-body-mini-cards-list-bg': '#f8f9f9', // #f8f9f9

    '--filter-head-item-active': '#FDBF00', //#FDBF00

    '--philanthropy-cause-card-border': 'var(--brand-color)',
    '--philanthropy-cause-img-bg': 'var(--brand-color)',
    '--philanthropy-cause-img-hover-bg':
      'linear-gradient(135deg, rgba(172, 138, 88, 0.25) 0%, rgba(173, 139, 91, 0.1875) 100%)',
    '--philanthropy-denom-bg-color': 'white',

    '--cause-list-page-bg-color': '#FAFAFA',
    '--cause-list-box-shadow-color': '0px 2px 10px rgba(0, 0, 0, 0.15)',
    '--cause-list-bg-color': 'white',

    '--offers-banner-bg': 'white',
    '--offers-coupon-modal-bg': '#f6f6f9',
    '--offer-coupon-modal-circle-left-bg': '#f6f6f9',
    '--offer-coupon-modal-circle-right-bg': '#f6f6f9',
    '--points-payment-cell-badge-color': '#FFD7D7',

    '--option-hover': 'rgba(253, 191, 0, 0.22)',

    '--input-box-color': '#F8F9F9',
    '--dark-gray': '#54565B',

    '--tertiary': '#485473 ',
    '--accent': '#54565B ',
    '--card-accent': '#54565B ',
    '--accenttwo': '#54565B ',
    '--darkblack': '#000 ',
    '--black': '#2E2E2E ',
    '--blacktwo': '#2E2E2E ',
    '--secblack': '#555151 ',
    '--black-1': '#64707C ',
    '--secblacktwo': 'rgba(85, 81, 81, 0.5) ',
    '--secblackthree': '#52555A ',
    '--grey': '#445261 ',
    '--greyone': '#64707C ',
    '--greytwo': 'rgba(128, 145, 163, 0.5) ',
    '--greythree': 'rgba(128, 145, 163, 0.3) ',
    '--greyfour': '#64707C',
    '--greyfive': '#8f97a0 ',
    '--secondarygrey': '#9A9393',
    '--background': '#F8F9F9 ',
    '--card-background': 'var(--background)',
    '--secondarybackground': '#FAFAFA ',
    // '--green': '#27AE60 ',
    '--greenone': '#219653 ',
    '--greentwo': '#055F2B ',
    '--greenBackground': '#EDFFF5 ',
    '--red': '#EB5757 ',
    '--redone': '#690101 ',
    '--redBackground': '#FFEDED ',
    '--option': '#F8F9F9 ',
    '--optionhover': '#F6F6F6 ',
    '--white': '#fff ',
    '--disabledbutton': '#C8D3DC ',
    '--stroke': '#ECEEEF ',
    '--border': '#DDE4E9',
    '--border-bottom': '#4990EF ',
    '--dark-blue': '#334C8B ',
    '--dark-bluetwo': '#03224C ',
    '--darkbluethree': '#213757 ',
    '--green': '#6FCF97 ',
    '--greyborder': '#E3E5E7',
    '--greytext': '#DBE0EB',
    '--hover': '#0b6aea ',
    '--graybackground': '#E5E5E5 ',
    '--errorborder': '#FF4C4C ',
    '--errorbackground': '#FFF2F2 ',
    '--info': '#445261 ',
    '--statusbackground': '#E4F2FF ',
    '--blueone': '#2484e8 ',
    '--bluetwo': '#56CCF2 ',
    '--orange': '#F7AA00 ',
    '--voilet': '#2D215D ',
    '--pink1': '#F7DDD8 ',
    '--tertiarybackground': '#F9FAFE ',
    '--yellowone': '#FFD5#4F ',
    '--primary-btn': '#FF3E3E ',
    '--text-primary': '#000',
    '--golf-dark-gradient': '#fdbf00',
    '--giftcard-mobile-bg': '#fbfbfb',
    '--charity-mob-bg': '#fafafa',
    '--main-bg': '#fafafa',
    '--golf-background': '#FFFFFF',
    '--golf-primary-1': 'var(--brand-color)',
    '--golf-primary-2': '#231F20',
    '--golf-text': '#231F20',
    '--golf-accent': '#A5A5A5',
    '--golf-accent-2': '#585858',
    '--golf-linear-gradient': 'var(--primary-btn)',
    '--golf-info-text': 'var(--golf-background)',
    '--golf-price': '#697581',
    '--card-border-radius': '12px',
    '--button-border-radius': '12px',
    '--primary-brown': 'var(--brand-color)',
    '--primary-btn-disabled': 'rgba(255, 62, 62,0.7)',

    '--footer-background': '#fff)',
    '--footer-text': 'var(--grey)',

    '--booking-confirmed-title': 'var(--black)',
    '--tabs-background': '#F8F9F9',
    '--tabs-border': '#DDE4E9',

    '--background-semantic-success': '#84bd00',
    '--background-semantic-warning': '#de6421',
    '--background-accent-light': '#ff5959',
    '--background-accent-light-opacity': '#ff595933',
    '--background-accent-medium': '#ff3e3e',
    '--background-accent-strong': '#d93434',
    '--background-neutral-10': '#eef2f5',
    '--background-neutral-100': '#172733',
    '--background-neutral-15': '#dde4e9',
    '--background-neutral-20': '#c7cfd5',
    '--background-neutral-30': '#b0b9c0',
    '--background-neutral-40': '#9ba4ab',
    '--background-neutral-60': '#6f7a81',
    '--background-neutral-70': '#59656d',
    '--background-neutral-80': '#455057',
    '--background-neutral-90': '#303c44',
    '--background-neutral-bg': '#fafdff',
    '--background-neutral-other': '#858f96',
    '--background-neutral-white': '#ffffff',
    '--background-secondary-light': '#f0b148',
    '--background-secondary-medium': '#c47f00',
    '--background-secondary-strong': '#a36a00',
    '--text-semantic-success': '#84bd00',
    '--text-semantic-warning': '#de6421',
    '--text-accent-light': '#ff5959',
    '--text-accent-light-opacity': '#ff595966',
    '--text-accent-medium': '#ff3e3e',
    '--text-accent-strong': '#d93434',
    '--text-neutral-10': '#eef2f5',
    '--text-neutral-15': '#dde4e9',
    '--text-neutral-20': '#c7cfd5',
    '--text-neutral-30': '#b0b9c0',
    '--text-neutral-40': '#9ba4ab',
    '--text-neutral-60': '#6f7a81',
    '--text-neutral-80': '#455057',
    '--text-neutral-90': '#303c44',
    '--text-neutral-bg': '#fafdff',
    '--text-neutral-body': '#59656d',
    '--text-neutral-other': '#858f96',
    '--text-neutral-title': '#172733',
    '--text-neutral-white': '#ffffff',
    '--text-secondary-light': '#f0b148',
    '--text-secondary-medium': '#c47f00',
    '--text-secondary-strong': '#a36a00',
    '--maskbg': 'rgba(0, 0, 0, 0.32)',
    '--feedback-background-positive-low-contrast': 'hsla(155, 100%, 31%, 0.18)',
    '--feedback-background-negative-low-contrast': 'hsla(9, 91%, 56%, 0.18)',
    '--feedback-background-notice-low-contrast': 'hsla(36, 100%, 44%, 0.09)',
    '--feedback-background-information-low-contrast':
      'hsla(193, 100%, 35%, 0.09)',
    '--feedback-background-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.09)',
    '--feedback-background-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-background-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-background-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-background-information-high-contrast':
      'hsla(193, 100%, 35%, 1)',
    '--feedback-background-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-border-positive-low-contrast': 'hsla(155, 100%, 31%, 0.32)',
    '--feedback-border-negative-low-contrast': 'hsla(9, 91%, 56%, 0.32)',
    '--feedback-border-notice-low-contrast': 'hsla(36, 100%, 44%, 0.32)',
    '--feedback-border-information-low-contrast': 'hsla(193, 100%, 35%, 0.32)',
    '--feedback-border-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.32)',
    '--feedback-border-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-border-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-border-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-border-information-high-contrast': 'hsla(193, 100%, 35%, 1)',
    '--feedback-border-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-text-positive': 'hsla(160, 100%, 26%, 1)',
    '--feedback-text-negative': 'hsla(8, 73%, 47%, 1)',
    '--feedback-text-notice': 'hsla(38, 97%, 38%, 1)',
    '--feedback-text-information': 'hsla(193, 100%, 35%, 1)',
    '--feedback-text-neutral': 'hsla(216, 27%, 36%, 1)',
    '--surface-border': 'var(--background-neutral-40)',
    '--surface-text-muted-low-contrast': 'var(--text-neutral-white)',
  },
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--primary-brown-radial':
      'radial-gradient(100% 100% at 0% 0%, #AD8B5B 0%, rgba(172, 138, 88, 0.5) 100%)',
    '--primary-brown': '#AC8A58',
    '--primary-bg-black': '#000000',
    '--primary-font-black': 'rgba(35, 31, 32, 0.9)',
    '--primary-font-light': 'white',
    '--brand-color': 'var(--primary-brown)',
    '--brand-color-filter':
      'invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%) invert(62%) sepia(5%) saturate(2898%) hue-rotate(356deg) brightness(90%) contrast(89%)',
    '--primary': 'var(--primary-brown)',
    '--primary-translucent': 'hsla(36, 34%, 51%, 50%)',
    '--icon-primary-black': 'rgba(35, 31, 32, 0.9)',
    '--primary-grey-accent': '#828282',

    // '--primary-grey-accent':'#828282',
    '--special-font': 'Retro Signature',
    '--header-background-color': 'white',
    '--header-header-links-bg-color': 'var(--primary-bg-black)',
    '--header-header-links-hover-bg-color': 'var(--primary-brown)',
    '--header-profile-dropdown-bg-color': 'white',
    '--header-profile-dropdown-option-hover-color': 'var(--primary-bg-black)', //D8D8D8
    '--header-profile-dropdown-font-color': 'var(--primary-font-black)', //333
    '--header-profile-dropdown-font-hover-color': 'white', //333
    '--header-profile-border-left': '#f0f0f0',
    '--login-button-color': 'var(--primary)',

    '--main-container-bg-color': 'white', //#fff
    '--main-container-font-color': 'var(--primary-font-black)',

    '--d-c-info-background': 'transparent',
    '--d-c-info-border-color': '#DDE4E9', // #C7CFD5
    '--d-c-icon-color': 'var(--brand-color)',

    '--reward-bar-background-color': 'var(--primary-bg-black)', //##FFEBBC
    '--home-bottom-background-color': 'white', // white,
    '--home-bottom-category-active-border-color': 'var(--brand-color)', // --brand-color
    '--giftcard-bg-color': 'white', // white
    '--giftcard-heading': '#342222', //#222222
    '--giftcard-primary-btn':
      'linear-gradient(90.07deg, #ECC17E 0.05%, #A68B61 99.94%)', //#ff3e3e

    '--membership-card-bg-color': 'white', // white
    '--membership-card-border-color': 'var(--primary-brown)', //transparent

    '--denomination-heading-bg-color':
      'linear-gradient(105.78deg, #FFFFFF -46.33%, #AD8B5A 194.72%)',
    '--denomination-heading-color': '#000',
    '--denomination-body-border-color': 'gold', // #f5f5f5,
    '--denomination-section-box-shadow': '0px 1px 20px rgb(0 0 0 / 5%)',
    '--denomination-nth-bg-color': '#fafafa', //#fafafa

    '--range-slider-knob': 'var(--primary-brown)', // brand-color
    '--range-slider-line': 'var(--primary-brown)', // #e0e0e0
    '--range-slider-ticks': 'var(--primary-brown)', // #d3d3d3
    '--range-slider-input': 'var(--primary-brown)', // #a1a8b0
    '--range-slider-input-text': 'var(--primary-brown)', // black

    '--send-enquiry-desktop-font-color': 'var(--primary-font-black)', // #333
    '--send-enquiry-desktop-background': 'white', // white

    '--list-body-mini-cards-list-bg': 'var(--giftcard-mobile-bg)', // #f8f9f9
    '--vantage-category-card-background': 'white',

    '--filter-head-item-active': 'gold', //#FDBF00

    '--philanthropy-cause-card-border': 'var(--primary-brown)',
    '--philanthropy-cause-img-bg': 'var(--primary-brown-radial)',
    '--philanthropy-cause-img-hover-bg':
      'linear-gradient(135deg, rgba(172, 138, 88, 0.25) 0%, rgba(173, 139, 91, 0.1875) 100%)',
    '--philanthropy-denom-bg-color': 'white',

    '--points-payment-cell-badge-color': '#EEE9E0',

    '--option-hover': '#FFF7EC',

    '--cause-list-page-bg-color': '#FAFAFA',
    '--cause-list-box-shadow-color': '0px 2px 10px rgba(0, 0, 0, 0.15)',
    '--cause-list-bg-color': 'white',

    '--offers-banner-bg':
      'linear-gradient(105.78deg, #FFFFFF -46.33%, #AD8B5A 194.72%)',
    '--offers-coupon-modal-bg':
      'linear-gradient(97.26deg, #493A25 1.42%, #130F0A 48.31%, #0E0B07 95.32%)',
    '--offer-coupon-modal-circle-left-bg': '#423621',
    '--offer-coupon-modal-circle-right-bg': '#0e0c08',

    '--input-box-color': '#F8F9F9',
    '--giftcard-mobile-bg': '#F4F6F8',
    '--dark-gray': '#54565B',
    '--tertiary': '#485473',
    '--accent': '#2e2e2e',
    '--card-accent': '#2e2e2e',
    '--accenttwo': '#54565B',
    '--darkblack': '#000',
    '--black': '#2E2E2E',
    '--blacktwo': '#2E2E2E',
    '--secblack': '#555151',
    '--black-1': '#64707C',
    '--secblacktwo': 'rgba(85, 81, 81, 0.5)',
    '--secblackthree': '#52555A',
    '--grey': '#445261',
    '--greyone': '#64707C',
    '--greytwo': 'rgba(128, 145, 163, 0.5)',
    '--greythree': 'rgba(128, 145, 163, 0.3)',
    '--greyfour': '#64707C',
    '--greyfive': '#8f97a0',
    '--secondarygrey': '#9A9393',
    '--background': '#F8F9F9',
    '--card-background': 'var(--background)',
    '--secondarybackground': '#FAFAFA',
    '--green': '#27AE60',
    '--greenone': '#219653',
    '--greentwo': '#055F2B',
    '--greenBackground': '#EDFFF5',
    '--red': '#EB5757',
    '--redone': '#690101',
    '--redBackground': '#FFEDED',
    '--option': '#F8F9F9',
    '--optionhover': '#F6F6F6',
    '--white': '#fff',
    '--disabledbutton': '#C8D3DC',
    '--stroke': '#ECEEEF',
    '--border': '#DDE4E9',
    '--border-bottom': '#4990EF',
    '--dark-blue': '#334C8B',
    '--dark-bluetwo': '#03224C',
    '--darkbluethree': '#213757',
    // '--green': '#6FCF97',
    '--greyborder': '#E3E5E7',
    '--greytext': '#DBE0EB',
    '--hover': '#0b6aea',
    '--graybackground': '#E5E5E5',
    '--errorborder': '#FF4C4C',
    '--errorbackground': '#FFF2F2',
    '--info': '#445261',
    '--statusbackground': '#E4F2FF',
    '--blueone': '#2484e8',
    '--bluetwo': '#56CCF2',
    '--orange': '#F7AA00',
    '--voilet': '#2D215D',
    '--pink1': '#F7DDD8',
    '--tertiarybackground': '#F9FAFE',
    '--yellowone': '#FFD54F',
    '--primary-btn': 'linear-gradient(90.07deg, #ECC17E 0.05%, #A68B61 99.94%)',
    '--primary-btn-disabled':
      'linear-gradient(90.07deg, #ECC17E 0.05%, #A68B61 99.94%)',
    '--greyscale-70': '#303C44',
    '--text-primary': '#FFF',
    '--golf-dark-gradient':
      'linear-gradient(97.26deg, #493A25 1.42%, #130F0A 48.31%, #0E0B07 95.32%)',
    '--charity-mob-bg': 'black',
    '--main-bg': '#fafafa',
    '--golf-background': '#FFFFFF',
    '--golf-primary-1': 'var(--brand-color)',
    '--golf-primary-2': '#231F20',
    '--golf-text': '#231F20',
    '--golf-accent': '#A5A5A5',
    '--golf-accent-2': '#585858',
    '--golf-linear-gradient': 'var(--primary-btn)',
    '--golf-info-text': 'var(--golf-background)',
    '--golf-price': '#697581',
    '--card-border-radius': '12px',
    '--button-border-radius': '12px',
    '--vertical-card-gcmo':
      'linear-gradient(102.71deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 269.24%, rgba(0, 0, 0, 0.75) 269.24%)',

    '--footer-background': '#fff)',
    '--footer-text': 'var(--grey)',

    '--booking-confirmed-title': 'var(--black)',
    '--tabs-background': '#F8F9F9',
    '--tabs-border': '#DDE4E9',

    '--background-semantic-success': '#84bd00',
    '--background-semantic-warning': '#d93434',
    '--background-accent-light': '#ecc17e',
    '--background-accent-light-opacity': '#ecc17e4d',
    '--background-accent-medium': '#cc9955',
    '--background-accent-strong': '#a68b61',
    '--background-neutral-0': '#fafdff',
    '--background-neutral-10': '#eef2f5',
    '--background-neutral-100': '#172733',
    '--background-neutral-15': '#dde4e9',
    '--background-neutral-20': '#c7cfd5',
    '--background-neutral-30': '#b0b9c0',
    '--background-neutral-40': '#9ba4ab',
    '--background-neutral-50': '#858f96',
    '--background-neutral-60': '#6f7a81',
    '--background-neutral-70': '#59656d',
    '--background-neutral-80': '#455057',
    '--background-neutral-90': '#303c44',
    '--background-neutral-white': '#ffffff',
    '--background-secondary-light': '#fac982',
    '--background-secondary-medium': '#c47f00',
    '--background-secondary-strong': '#a36a00',
    '--text-semantic-success': '#84bd00',
    '--text-semantic-warning': '#d93434',
    '--text-accent-light': '#ecc17e',
    '--text-accent-light-opacity': '#ecc17e4d',
    '--text-accent-medium': '#cc9955',
    '--text-accent-strong': '#a68b61',
    '--text-neutral-0': '#fafdff',
    '--text-neutral-10': '#eef2f5',
    '--text-neutral-100': '#172733',
    '--text-neutral-15': '#dde4e9',
    '--text-neutral-20': '#c7cfd5',
    '--text-neutral-30': '#b0b9c0',
    '--text-neutral-40': '#9ba4ab',
    '--text-neutral-50': '#858f96',
    '--text-neutral-60': '#6f7a81',
    '--text-neutral-80': '#455057',
    '--text-neutral-90': '#303c44',
    '--text-neutral-body': '#59656d',
    '--text-neutral-title': '#172733',
    '--text-neutral-white': '#ffffff',
    '--text-secondary-light': '#fac982',
    '--text-secondary-medium': '#c47f00',
    '--text-secondary-strong': '#a36a00',
    '--maskbg': 'rgba(0, 0, 0, 0.32)',
    '--feedback-background-positive-low-contrast': 'hsla(155, 100%, 31%, 0.18)',
    '--feedback-background-negative-low-contrast': 'hsla(9, 91%, 56%, 0.18)',
    '--feedback-background-notice-low-contrast': 'hsla(36, 100%, 44%, 0.09)',
    '--feedback-background-information-low-contrast':
      'hsla(193, 100%, 35%, 0.09)',
    '--feedback-background-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.09)',
    '--feedback-background-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-background-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-background-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-background-information-high-contrast':
      'hsla(193, 100%, 35%, 1)',
    '--feedback-background-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-border-positive-low-contrast': 'hsla(155, 100%, 31%, 0.32)',
    '--feedback-border-negative-low-contrast': 'hsla(9, 91%, 56%, 0.32)',
    '--feedback-border-notice-low-contrast': 'hsla(36, 100%, 44%, 0.32)',
    '--feedback-border-information-low-contrast': 'hsla(193, 100%, 35%, 0.32)',
    '--feedback-border-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.32)',
    '--feedback-border-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-border-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-border-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-border-information-high-contrast': 'hsla(193, 100%, 35%, 1)',
    '--feedback-border-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-text-positive': 'hsla(160, 100%, 26%, 1)',
    '--feedback-text-negative': 'hsla(8, 73%, 47%, 1)',
    '--feedback-text-notice': 'hsla(38, 97%, 38%, 1)',
    '--feedback-text-information': 'hsla(193, 100%, 35%, 1)',
    '--feedback-text-neutral': 'hsla(216, 27%, 36%, 1)',
    '--surface-border': 'var(--background-neutral-40)',
    '--surface-text-muted-low-contrast': 'var(--text-neutral-white)',
  },
};

export const treasures: Theme = {
  name: 'treasures',
  properties: {
    '--black': '#000000',
    '--primary': '#CC9955',
    '--primary-translucent': 'hsla(34, 54%, 57%, 0.5)',
    '--primary-offset': '#bc8c4c',
    '--primary-btn': 'var(--primary)',
    '--brand-color': '#AC8A58',
    '--brand-color-filter':
      'invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%) invert(62%) sepia(5%) saturate(2898%) hue-rotate(356deg) brightness(90%) contrast(89%)',
    '--main-container-font-color': '#BCBCBC',
    '--background': 'var(--black)',
    '--accent': '#FFFFFF',
    '--border': 'var(--black)',
    '--disabled-gray': '#979797',

    '--header-background-color': '#000000',
    '--login-button-color': 'var(--primary)',
    '--header-profile-border-left': '#000000',
    '--points-payment-cell-badge-color': '#EEE9E0',

    '--golf-background': '#000000',
    '--golf-info-text': '#FFFFFF',
    '--golf-price': '#CAD6E1',
    '--golf-primary-1': '#000000',
    '--golf-primary-2': '#FFFFFF',
    '--golf-text': '#231F20',
    '--golf-dark-gradient': 'var(--primary)',
    '--golf-accent-2': '#FFFFFF',
    '--golf-heading': '#FFFFFF',

    '--footer-background': '#000000',
    '--footer-text': '#DBE0EB',

    '--success-text': '#008659',
    '--error-text': '#B42318',

    '--disabled-bg': '#C7C7C7',

    '--box-shadow-light': '0px 0px 10px 0px rgba(255, 255, 255, 0.5)',

    '--benefit-form-background':
      'linear-gradient(123deg, #000 0.01%, rgba(0, 0, 0, 1) 32.5%, rgba(0, 0, 0, 1) 21.7%, rgba(0, 0, 0, 1) 30.6%, rgba(0, 0, 0, 1) 38.61%, rgba(0, 0, 0, 0.80) 46.61%, #000 67.29%, rgba(0, 0, 0, 0.95) 67.5%, rgba(0, 0, 0, 0.90) 73.72%, rgba(0, 0, 0, 0.95) 79.53%, rgba(0, 0, 0, 0.90) 88.79%, #000 99.53%)',
    '--benefit-form-divider':
      'linear-gradient(90deg,rgba(255, 255, 255, 0) 0%,#fff 50%,rgba(255, 255, 255, 0) 100%)',

    '--input-placeholder': '#9BA4AB',
    '--form-button-disabled': '#768EA7',
    '--input-dropdown-bg': '#DDE4E9',

    '--card-background': 'var(--black)',
    '--card-background-secondary': '#CC995580',
    '--card-accent': '#FFFFFF',
    '--card-box-shadow': 'rgba(0, 0, 0, 0.16) 0px 1px 4px',

    '--booking-confirmed-title': '#FFFFFF',
    '--tabs-background': '#FFFFFF',
    '--tabs-border': '#DDDCDC',
    '--golf-search-color': 'var(--black)',
    '--treasures-light': '#E7E7E7',
    '--secondarybackground': 'var(--black)',

    '--background-semantic-success': '#84bd00',
    '--background-semantic-warning': '#d93434',
    '--background-accent-light': '#ecc17e',
    '--background-accent-light-opacity': '#ecc17e4d',
    '--background-accent-medium': '#cc9955',
    '--background-accent-strong': '#a68b61',
    '--background-neutral-0': '#fafdff',
    '--background-neutral-10': '#eef2f5',
    '--background-neutral-100': '#172733',
    '--background-neutral-15': '#dde4e9',
    '--background-neutral-20': '#c7cfd5',
    '--background-neutral-30': '#b0b9c0',
    '--background-neutral-40': '#9ba4ab',
    '--background-neutral-50': '#858f96',
    '--background-neutral-60': '#6f7a81',
    '--background-neutral-70': '#59656d',
    '--background-neutral-80': '#455057',
    '--background-neutral-90': '#303c44',
    '--background-neutral-white': '#ffffff',
    '--background-secondary-light': '#fac982',
    '--background-secondary-medium': '#c47f00',
    '--background-secondary-strong': '#a36a00',
    '--text-semantic-success': '#84bd00',
    '--text-semantic-warning': '#d93434',
    '--text-accent-light': '#ecc17e',
    '--text-accent-light-opacity': '#ecc17e4d',
    '--text-accent-medium': '#cc9955',
    '--text-accent-strong': '#a68b61',
    '--text-neutral-0': '#fafdff',
    '--text-neutral-10': '#eef2f5',
    '--text-neutral-100': '#172733',
    '--text-neutral-15': '#dde4e9',
    '--text-neutral-20': '#c7cfd5',
    '--text-neutral-30': '#b0b9c0',
    '--text-neutral-40': '#9ba4ab',
    '--text-neutral-50': '#858f96',
    '--text-neutral-60': '#6f7a81',
    '--text-neutral-80': '#455057',
    '--text-neutral-90': '#303c44',
    '--text-neutral-body': '#59656d',
    '--text-neutral-title': '#172733',
    '--text-neutral-white': '#ffffff',
    '--text-secondary-light': '#fac982',
    '--text-secondary-medium': '#c47f00',
    '--text-secondary-strong': '#a36a00',
    '--maskbg': 'rgba(0, 0, 0, 0.32)',
    '--feedback-background-positive-low-contrast': 'hsla(155, 100%, 31%, 0.18)',
    '--feedback-background-negative-low-contrast': 'hsla(9, 91%, 56%, 0.18)',
    '--feedback-background-notice-low-contrast': 'hsla(36, 100%, 44%, 0.09)',
    '--feedback-background-information-low-contrast':
      'hsla(193, 100%, 35%, 0.09)',
    '--feedback-background-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.09)',
    '--feedback-background-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-background-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-background-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-background-information-high-contrast':
      'hsla(193, 100%, 35%, 1)',
    '--feedback-background-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-border-positive-low-contrast': 'hsla(155, 100%, 31%, 0.32)',
    '--feedback-border-negative-low-contrast': 'hsla(9, 91%, 56%, 0.32)',
    '--feedback-border-notice-low-contrast': 'hsla(36, 100%, 44%, 0.32)',
    '--feedback-border-information-low-contrast': 'hsla(193, 100%, 35%, 0.32)',
    '--feedback-border-neutral-low-contrast': 'hsla(216, 15%, 54%, 0.32)',
    '--feedback-border-positive-high-contrast': 'hsla(160, 100%, 26%, 1)',
    '--feedback-border-negative-high-contrast': 'hsla(8, 73%, 47%, 1)',
    '--feedback-border-notice-high-contrast': 'hsla(38, 97%, 38%, 1)',
    '--feedback-border-information-high-contrast': 'hsla(193, 100%, 35%, 1)',
    '--feedback-border-neutral-high-contrast': 'hsla(216, 33%, 29%, 1)',
    '--feedback-text-positive': 'hsla(160, 100%, 26%, 1)',
    '--feedback-text-negative': 'hsla(8, 73%, 47%, 1)',
    '--feedback-text-notice': 'hsla(38, 97%, 38%, 1)',
    '--feedback-text-information': 'hsla(193, 100%, 35%, 1)',
    '--feedback-text-neutral': 'hsla(216, 27%, 36%, 1)',
    '--surface-border': 'var(--background-neutral-40)',
    '--surface-text-muted-low-contrast': 'var(--text-neutral-white)',
    '--bs-primary-rgb': 'var(--treasures-light)',
    '--white-filter':
      'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(150%) contrast(100%)',
    '--tnc-table-border': '#ddd',
  },
};
