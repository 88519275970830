export const appConstants = {
  emailRegexPattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
  dbsDelightsTitle: 'DBS Lifestyle Benefits',
  dbsTreasuresTitle: 'DBS Treasures',
  dbsTreasuresUrlKeyword: 'treasures',
  treasures: {
    benefits: {
      AirportMeetAndGreet: {
        image: 'https://images.poshvine.com/dbs/meet-and-greet.webp',
        title: 'Airport Meet and Greet',
        description: 'Arrive like a VIP with our Meet and Greet service',
        action: 'AirportMeetAndGreet',
        icon: 'https://images.poshvine.com/dbs/airport-meet-greet-icon.svg',
        iconAlt: 'Handshake icon',
        serviceType: 'ConciergeRequest-AirportMeetAndGreet',
      },
      AirportPickupDrop: {
        image: 'https://images.poshvine.com/dbs/pickup-drop.webp',
        title: 'Airport Pickup/Drop',
        description:
          'Elevate your journey with seamless pickup & drop services.',
        action: 'AirportPickupDrop',
        icon: 'https://images.poshvine.com/dbs/airport-pickup-drop-icon.svg',
        iconAlt: 'A car icon',
        serviceType: 'ConciergeRequest-AirportPickupDrop',
      },
      InternationalLoungeAccess: {
        image: 'https://images.poshvine.com/dbs/lounge-access.webp',
        title: 'International Lounge Access',
        description: 'Indulge in luxury while you wait for your next flight.',
        action: 'InternationalLoungeAccess',
        icon: 'https://images.poshvine.com/dbs/international-lounge-access-icon.svg',
        iconAlt: 'A couch icon',
        serviceType: 'ConciergeRequest-InternationalLoungeAccess',
      },
      GiftVouchers: {
        image: 'https://images.poshvine.com/dbs/voucher.webp',
        title: 'Luxury Brand Vouchers',
        description:
          'Experience opulence with e-Vouchers from the best brands.',
        action: '/treasures/gift-vouchers',
      },
      Memberships: {
        image: 'https://images.poshvine.com/dbs/voucher.webp',
        title: 'Membership Plans',
        description: 'Unlock a world of membership/subscription plans.',
        action: '/treasures/memberships',
      },
      GolfBooking: {
        image: 'https://images.poshvine.com/dbs/booking.webp',
        title: 'Golf Booking',
        description:
          'Tee-off in style at the best golf courses across the country',
        action: '/golf',
      },
    },
    starterSteps: [
      {
        image: 'https://images.poshvine.com/dbs/otp-verify.svg',
        imageAlt: 'otp verification',
        title: 'Verify Yourself',
        description: 'Login using your mobile number registered with the bank.',
      },
      {
        image: 'https://images.poshvine.com/dbs/benefits.svg',
        imageAlt: 'different benefit symbols',
        title: 'Explore Benefits',
        description: 'Choose and redeem benefits based on your tier.',
      },
      {
        image: 'https://images.poshvine.com/dbs/team.svg',
        imageAlt: 'customer support',
        title: 'Enjoy Your Benefits',
        description:
          'Sit back, relax and let our team take care of your needs!',
      },
    ],
    faqs: [
      {
        question:
          'What are the benefits I can avail as a DBS Treasures account holder?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        id: 1,
      },
      {
        question: 'How can I avail my benefits?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        id: 2,
      },
      {
        question: 'How will I receive the details of these benefits?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        id: 3,
      },
      {
        question: 'I am unable to login to the portal. What is the issue?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        id: 4,
      },
      {
        question: 'I have more questions. Whom should I contact?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        id: 5,
      },
    ],
    sideHeaderLinks: [
      {
        title: 'Airport Meet and Greet',
        src: 'https://images.poshvine.com/dbs/flights.svg',
        action: 'AirportMeetAndGreet',
      },
      {
        title: 'Airport Pickup/Drop',
        src: 'https://images.poshvine.com/dbs/flights.svg',
        action: 'AirportPickupDrop',
      },
      {
        title: 'International Lounge Access',
        src: 'https://images.poshvine.com/dbs/hotels.svg',
        action: 'InternationalLoungeAccess',
      },
      {
        title: 'Luxury Brand Vouchers',
        src: 'https://images.poshvine.com/dbs/giftcards.svg',
        action: '/treasures/gift-vouchers',
      },
      {
        title: 'Membership Plans',
        src: 'https://images.poshvine.com/dbs/memberships.svg',
        action: '/treasures/memberships',
      },
      {
        title: 'Golf Bookings',
        src: 'https://images.poshvine.com/dbs/golf.svg',
        action: '/golf',
      },
    ],
    images: {
      treasuresLogo: 'https://images.poshvine.com/dbs/dbs_treasures.webp',
      treasuresLogoDark:
        'https://images.poshvine.com/dbs/dbs_treasures_dark.webp',
      successLogo: 'https://images.poshvine.com/dbs/gold-success.svg',
      failureLogo: 'https://images.poshvine.com/dbs/red-failure.svg',
    },
    redemptionTabs: [
      {
        id: 'T1',
        cusname: 'Buy Luxury Brand Vouchers',
        name: 'Luxury Brand Vouchers',
        url: '/gift-vouchers',
      },
      {
        id: 'T2',
        cusname: 'Buy Membership Plans',
        name: 'Membership Plans',
        url: '/campaign-memberships',
      },
      {
        id: 'T3',
        cusname: 'Airport Meet and Greet',
        name: 'Airport Meet and Greet',
        url: '/airport-meet-and-greet',
      },
      {
        id: 'T4',
        cusname: 'Airport Pickup/Drop',
        name: 'Airport Pickup/Drop',
        url: '/airport-pickup-drop',
      },
      {
        id: 'T5',
        cusname: 'International Lounge Access',
        name: 'International Lounge Access',
        url: '/international-lounge-access',
      },
      {
        id: 11,
        cusname: 'Book Golf',
        name: 'Golf Booking',
        url: '/golf-booking',
      },
    ],
    bookingStatus: {
      success: 'success',
      error: 'error',
    },
  },
  delightsSideHeaderLinks: [
    {
      title: 'Deals & Offers',
      src: 'https://images.poshvine.com/dbs/hamburger-offers.svg',
      link: '/offers',
      clickFunction: 'toggleHamburger()',
      superscript: '',
    },
    {
      title: 'Exclusive Rewards',
      src: 'https://images.poshvine.com/dbs/hamburger-exclusive-rewards.svg',
      link: 'booking/home?app=Exclusive%20Rewards',
      clickFunction: 'navigateToRewards()',
      superscript: '',
    },
    {
      title: 'Discount on Gift Cards',
      src: 'https://images.poshvine.com/dbs/hamburger-offers.svg',
      link: '/offers-on-giftcards',
      clickFunction: 'toggleHamburger()',
      superscript: 'New',
    },
  ],
  cardIconsMapping: {
    VISA: 'visa.png',
    AMEX: 'amex-512.png',
    DINR: 'diners-club.png',
    MAST: 'master.png',
    RUPAY: 'RuPay.png',
    MASTERCARD: 'master.png',
    Unknown: 'credit.png',
  },
  apps: [
    {
      id: 26,
      cusname: 'Transfer Your Points',
      name: 'Points Xchange',
      avatar: 'icon-redeem-points mar-right-10 mar-top-4',
      url: '/points-xchange/home',
      specificTo: null,
      icon: 'points-xchange',
    },
    {
      id: 4,
      cusname: 'Buy Gift Cards',
      name: 'Gift Cards',
      avatar: 'icon-gift-card mar-right-10 mar-top-4',
      url: '/giftcards',
      specificTo: null,
      icon: 'giftcards',
    },
    {
      id: 13,
      cusname: 'Buy Memberships',
      name: 'Memberships',
      avatar: 'icon-13 mar-right-10 mar-top-4',
      url: '/memberships',
      specificTo: null,
      icon: 'memberships',
    },
    {
      id: 1,
      cusname: 'Book Flights',
      name: 'Flights',
      avatar: 'icon-flight mar-right-10 mar-top-4',
      url: '/flights',
      specificTo: null,
      icon: 'flights',
    },
    {
      id: 2,
      cusname: 'Book Hotels',
      name: 'Hotels',
      avatar: 'icon-hotels mar-right-10 mar-top-4',
      url: '/hotels',
      specificTo: null,
      icon: 'hotels',
    },
    {
      id: 5,
      cusname: 'Donate to Charity',
      name: 'Philanthropy',
      avatar: 'icon-charity mar-right-10 mar-top-4',
      url: '/charitable-donations',
      specificTo: null,
      icon: 'charitable-donations',
    },
    {
      id: 14,
      cusname: 'Book Bus Tickets',
      name: 'Bus',
      avatar: 'icon-bus mar-right-10 mar-top-4',
      url: '/bus',
      specificTo: 'BFL/Spark',
      icon: 'bus',
    },
    {
      id: 15,
      cusname: 'Exclusive Rewards',
      name: 'Exclusive Rewards',
      avatar: 'icon-charity mar-right-10 mar-top-4',
      url: '/exclusive-rewards',
      specificTo: null,
      icon: 'exclusive-rewards',
    },
    {
      id: 11,
      cusname: 'Golf',
      name: 'Golf',
      avatar: 'icon-golf mar-right-10 mar-top-4',
      url: '/golf',
      specificTo: 'Vantage',
      icon: 'golf',
    },
  ],
  basicInfo: {
    email: {
      display: 'aurumrewards@poshvine.com',
      val: 'aurumrewards@poshvine.com',
    },
    phone: { display: '1800 212 7078', val: '18002127078' },
  },
  routesMapping: [
    { HotelBooking: 'hotels' },
    { NatGeoTrack: 'nat-geo' },
    { GolfEnquiry: 'golf' },
    { FlightItinerary: 'flights' },
    { GiftCardBooking: 'giftcards' },
    { HeadoutBooking: 'tours-and-activities' },
    { NgoDonation: 'charitable-donations' },
    { FnpBooking: 'gifting' },
    { DocsAppBooking: 'doctor-consultation' },
    { EventBooking: 'events-concerts' },
    { GacBooking: 'airport-concierge' },
    { DesignerBooking: 'designers' },
    { ConciergeRequest: 'concierge-services' },
    { HealthPassRedemption: 'offers' },
    { RestaurantBooking: 'dining' },
  ],
  cardNames: {
    vantage: 'DBS Vantage Card',
    mass: 'DBS Spark',
  },
};
