import { PointsXChangeConfig } from '@pv-frontend/points-xchange';

export const pointsXChangeConfig: PointsXChangeConfig = {
  global: {
    config: {
      pointsLabel:
        localStorage.getItem('cardType') === 'vantage'
          ? 'Vantage Points'
          : 'Cash Points',
      defaultBurnRatio: 1,
    },
  },
  landingPage: {
    listing: {
      config: {
        headingText: 'Our trusted point transfer partners',
        categories: {
          showCrossIcon: true,
          isMultiSelect: true,
          variant: 'outlined',
        },
        pointsProvider: {
          isPaginationRequired: true,
          perPage: 6,
        },
        showLinkedProviders: true,
        showRepeatTransactions: false,
      },
    },
  },
  transferHistoryPage: {
    style: {
      container: {
        'background-color': '#fff !important',
      },
      subContainer: {
        width: '100%',
      },
      navBar: {
        container: {
          display: 'none',
        },
      },
      listing: {
        transferData: {
          container: {
            border: '1px solid var(--greyborder)',
            borderRadius: '12px',
          },
        },
      },
    },
  },
  transferHistoryDetailsPage: {
    config: {
      backNavUrl: {
        route: ['booking', 'home'],
        queryParams: { app: 'Points Xchange' },
      },
    },
  },
};
