import { appConstants } from 'src/app/app.constants';
import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { SpecialProjectCheck } from 'src/app/shared/services/common/special-project-check';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { environment } from 'src/environments/environment';

const loginApis = ['cs/v1/bins/validate'];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private clientId: string;
  private projectId: string;
  environment_type: any;
  dynamicProjectId: any;
  currentUserCard: any;
  appConstants = appConstants;
  private isCurrentDomainTreasures: boolean = window.location.hostname.includes(
    appConstants.dbsTreasuresUrlKeyword
  );

  constructor(
    @Inject(DOCUMENT) private document: any,
    public GlobalConstants: GlobalConstants,
    public SpecialProjectCheck: SpecialProjectCheck,
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('restcountries') ||
      request.url.includes('ipify.org') ||
      request.url.includes('postalpincode') ||
      request.url.includes('opencagedata')
    ) {
      return next.handle(request);
    }
    let cardObject;
    if (localStorage.getItem('userCard')) {
      cardObject = JSON.parse(localStorage.getItem('userCard') || '{}');
    }
    this.dynamicProjectId = false;
    this.environment_type = environment.production ? 'prod' : 'sandbox';
    this.clientId =
      this.GlobalConstants.data.x_header[this.environment_type].client_id;

    if (this.SpecialProjectCheck.checkProject().type === 'special') {
      this.dynamicProjectId = true;
    }
    if (this.isCurrentDomainTreasures) {
      this.projectId =
        this.GlobalConstants.data.x_header[
          this.environment_type
        ].treasures_project_id;
    } else if (
      loginApis.some((v) => request.url.includes(v)) &&
      (localStorage.getItem('redirectData') ||
        localStorage.getItem('headerLogin'))
    ) {
      var redirectData = JSON.parse(localStorage.getItem('redirectData'));
      var headerLogin = localStorage.getItem('headerLogin');
      if (headerLogin) {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].main_project;
      } else if (redirectData) {
        this.projectId = redirectData.projectId;
      }
    } else if (this.dynamicProjectId) {
      this.projectId =
        this.GlobalConstants.data.x_header[this.environment_type].sub_project;
    } else {
      if (
        cardObject?.attributes?.display_name ===
          appConstants.cardNames.vantage &&
        !request.headers.get('discounted_project_id')
      ) {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].alternate_project;
      } else if (
        cardObject?.attributes?.display_name.includes(
          appConstants.cardNames.mass
        )
      ) {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].mass_project_id;
      } else if (request.headers.get('discounted_project_id')) {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].discounted_project_id;
      } else {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].main_project;
      }
    }

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '-1',
        'X-Download-Options': 'noopen',
        'X-Client-Id': this.clientId,
      },
    });
    if (!request.headers.get('exclude_project_id')) {
      request = request.clone({
        setHeaders: {
          'X-Project-Id': this.projectId,
        },
      });
    }
    if (localStorage.getItem('userCard')) {
      this.currentUserCard = JSON.parse(localStorage.getItem('userCard'));
      request = request.clone({
        setHeaders: {
          'X-User-Card-Id': this.currentUserCard.id,
        },
      });
    }

    var currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + currentUser.token,
          },
        });
      }
    } else if (this.pvUserService.getToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.pvUserService.getToken(),
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          return response;
        },
        (error: HttpErrorResponse) => {
          return error;
        },
        () => {}
      )
    );
  }
}
