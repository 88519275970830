import { Environment } from './type';

export const environment: Environment = {
  production: true,
  corePath: 'https://api-customer.poshvine.com/core_service/v1',
  corePathV2: 'https://api-customer.poshvine.com/cs/v1',
  offerPath: 'https://api-customer.poshvine.com/offer_service/v1',
  giftPathV2: 'https://offers-api.poshvine.com/offer_service/v2',
  offerPathV2: 'https://api-customer.poshvine.com/ps/v1',
  apiUrlBsV1: 'https://api-customer.poshvine.com/bs/v1/',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiUrlLsV1: 'https://api-customer.poshvine.com/ls/v1/',
  baseUrl: 'https://api-customer.poshvine.com/',
  apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  apiUrlBsV1Internal: 'https://api-customer.poshvine.com/bs/internal',
  stagingUrl: 'https://rewardshub-stage-api.poshvine.com/',
  perksUrl: 'https://api-customer.poshvine.com/ps/rewardshub/',
  rewardsHubApiUrl: 'https://rewards-api.poshvine.com/api/v1/',
  analytics:
    'https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js',
  gmApiKey: 'AIzaSyAXREnbpzSGulf8xS5J43tsYwrbBFltR7k', //'AIzaSyC2AtPyxZua0AcvWyoPZi-M_WuXoOo_gRc'
  secretKey: '000102030405060708090a0b0c0d0e0f',
  campaignPath: 'https://api-customer.poshvine.com/cas/v1/',
  campaignEnterprisePath: 'https://api-customer.poshvine.com/cas/enterprise/',
  golfComplimentaryBookingCode: 'dbs-golf-comp',
  apiPerksServiceUrl: 'https://api-customer.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://api-customer.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api-customer.poshvine.com/cs/v1',
  apiBookingServiceUrl: 'https://api-customer.poshvine.com/bs/v1',
  apiPointsServiceUrl: 'https://api-customer.poshvine.com/pts/v1',
  treasuresGolfComplimentaryBookingCode: 'dbs-treassures-golf-comp',
  isDBSTreasures: false,
  sentryDsn:
    'https://85c642884ebd9182416c2f7eb5e829a3@o515678.ingest.us.sentry.io/4506947222044672',
  SENTRY_ORG: 'rzp',
  SENTRY_PROJECT: 'dbs-all',
  projectLink: 'https://www.dbsdelights.in/',
  envName: 'production',
};
